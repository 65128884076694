/* eslint-disable */
export const messagesPL = {
    login: {
        title: "Logowanie",
        subtitle: "Mam konto",
        description: "Zaloguj się, by móc zarządzać awariami",
        publicDescription: "Zaloguj się, aby otrzymywać powiadomienia o przerwach",
        login: "Login",
        eonLogin: "E-mail",
        password: "Hasło",
        action: {
            login: "Zaloguj się",
            register: "Zarejestruj się",
            remindPassword: "Przypomnij hasło",
            eonRemindPassword: "Nie pamiętam hasła",
            send: "Wyślij",
            remindPasswordDetails: "Podaj adres e-mail użyty podczas zakładania konta, a prześlemy Ci link do zmiany hasła.",
        },
    },
    register: {
        title: "Nie masz konta w Portalu planowanych wyłączeń i awarii?",
        publicMessage: "Załóż je, aby otrzymywać powiadomienia o przerwach w dostawie energii elektrycznej w Twojej okolicy.",
        message: "Aby zarządzać przerwami, zgłoś się do swojego opiekuna.",
        register: "Zarejestruj się",
        login: "Login",
        email: "E-mail",
        password: "Hasło",
        fullName: "Imię i nazwisko",
        confirmEmailMessage: "Adres e-mail został potwierdzony.",
        stopSendEmailSuccessMessage: "Powiadomienia mailowe zostały wyłączone.",
        stopSendEmailErrorMessage: "Coś poszło nie tak. Skontaktuj się z administratorem systemu.",
        action: {
            register: "Zarejestruj się",
            remindPassword: "Przypomnij hasło",
            alreadyHaveAccount: "Mam już konto",
            send: "Wyślij",
            remindPasswordDetails: "Podaj adres e-mail użyty podczas zakładania konta, a prześlemy Ci link do zmiany hasła.",
        },
        info: {
            title: "Dlaczego warto?",
            bullet1: "Powiadomienia SMS i email o awariach oraz planowanych przerwach",
            bullet2: "Możliwość wybrania wielu lokalizacji",
            bullet3: "Podgląd zgłoszonych przez Ciebie awarii",
        },
    },
    cookies: {
        accept: "Rozumiem",
        acceptNecessary: "Zgadzam się na niezbędne",
        title: "Ta strona korzysta z ciasteczek, aby świadczyć usługi na najwyższym poziomie",
        message: "Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.",
        policyLinkPref: "Szczegółowe informacje dotyczące polityki prywatności znajdziesz ",
        policyLink: "tutaj",
        policyLinkSuf: ".",
    },
    menu: {
        users: "Użytkownicy",
    },
    other: {
        emptyTable: "Brak wyników",
        emptyValue: "Brak",
        allValues: "Wszystkie"
    },
    validations: {
        required: "Pole obowiązkowe",
        email: "Wpisz poprawny email",
        confirmed: "Wartości nie są takie same",
        min: "Wymagana długość: 0:{min}",
        max: "Maksymalna długość: 0:{max}",
        min_value: "Minimalna wartość: 0:{min_value}",
        max_value: "Maksymalna wartość: 0:{max_value}",
        dateAfter: "Czas zakończenia nie może być wcześniej od czasu rozpoczęcia",
        is: "",
        regex: "",
        validPassword: "Hasło musi mieć długość co najmniej 8 znaków, zawierać małą i dużą literę, cyfrę, oraz znak specjalny",
        postCode: "Wpisz poprawny kod pocztowy",
        phoneNumber: "Zbyt krótki numeru telefonu",
        phoneNumberPL: "Zbyt krótki numeru telefonu",
    },
    addReport: {
        yes: "Tak",
        no: "Nie",
        healthDanger: "Sytuacja zagrażająca życiu i zdrowiu",
        powerFailure: "Awaria zasilania",
        other: "Inne",
        brokenCable: "Zerwany kabel",
        fire: "Pożar",
        openedCableConnections: "Otwarte złącza kablowe",
        brokenWires: "Zerwane przewody",
        no2Phase: "Brak dwóch faz",
        no1Phase: "Brak fazy",
        no3Phase: "Brak trzech faz",
        noVoltage: "Braku zasilania",
        partialVoltageLack: "Częściowego braku zasilania",
        completeVoltageLack: "Całkowitego braku zasilania",
        highVoltage: "Za wysokiego napięcia",
        lowVoltage: "Za niskiego napięcia",
        voltageFluctuation: "Wahań napięcia",
        counterMalfunction: "Uszkodzenia pod licznikiem",
        flat: "Obiektu wielolokalowego",
        house: "Obiektu jednolokalowego",
        checkedFuses: "Wszystkie bezpieczniki w lokalu są włączone",
        invoicesPaid: "Wszystkie faktury za energię elektryczną są opłacone",
        checkedElectricityMeter: "Bezpieczniki przedlicznikowe są włączone (na klatce schodowej)",
        checkedNeighbourhood: "u sąsiadów i w częściach wspólnych budynku jest światło",
        checkedFusesHouse: "Wszystkie bezpieczniki w lokalu są włączone",
        checkedElectricityMeterHouse: "Bezpieczniki przedlicznikowe są włączone",
        checkedNeighbourhoodHouse: "w sąsiednich budynkach jest włączone światło",
        RODO: "Potwierdzam, że zapoznałem się i akceptuję klauzulę informacyjną.",
        airLine: "linia napowietrzna",
        cable: "linia kablowa",
    },
    poll: {
        reportFailureTarget: "W celu zgłoszenia awarii",
        failureCheckTarget: "W celu sprawdzenia gdzie aktualnie występują awarie",
        plannedCheckTarget: "W celu sprawdzenia planowanych wyłączeń",
        yes: "Tak",
        no: "Nie",
        dialogTitle: "Chcemy poznać Twoją opinię o Portalu planowanych wyłączeń i awarii",
        dialogSubtitle: "Poświęć nam chwilę i wypełnij ankietę",
        never: "Nie pokazuj ponownie",
        later: "Nie, dziękuję",
        goToPoll: "Przejdź do ankiety"
    }
};
