<template>
    <div class="public-user-reports-wrapper">
        <div class="public-user-reports-bg">
            <div class="public-user-reports-header-container">
                <div class="public-user-reports-header-container__text">
                    <h1>Moje zgłoszenia</h1>
                </div>
                <div class="public-user-reports-header-container__img"></div>
            </div>
        </div>
        <div class="public-user-reports-table">
            <div class="public-user-reports-table-search-criteria">
                <EonTimePicker width="280" name="picker" label="Data zgłoszenia" :show-time="false"
                               v-model="searchCriteria.reportDate" @update:modelValue="search" showLabel/>
            </div>
            <DataTable :value="myReports" lazy
                       :search-criteria="searchCriteria" @page="onPage" @sort="onPage"
                       :loading="loading" removableSort
                       class="reports-table"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
                       LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate="Elementy na stronie"
                       responsiveLayout="scroll" style="padding-bottom: 50px;">
                <Column header="Adres">
                    <template #body="slotProps">
                        {{ computedAddress(slotProps.data) }}
                    </template>
                </Column>
                <Column header="Data zgłoszenia" field="reportDate" sortable />
                <Column header="Treść" field="comment" />
            </DataTable>
        </div>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import EonInputText from "@/components/form/EonInputText";
    import EonTimePicker from "@/components/form/EonTimePicker";
    import {getUserReports} from "@/swagger/vue-api-client";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {TimeUtils} from "@/utils/TimeUtils";

    export default {
        name: "PublicUserReportsView",

        components: {
            DataTable,
            Column,
            EonInputText,
            EonTimePicker,
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                loading: false,
                myReports: [],
                searchCriteria: {
                    reportDate: "",
                    sortField: null,
                    sortOrder: null,
                },
            };
        },

        mounted() {
            this.onPage({});
        },

        methods: {
            search() {
                this.onPage({});
            },

            onPage(event) {
                this.loading = true;

                this.searchCriteria.sortField = event.sortField;
                this.searchCriteria.sortOrder = event.sortOrder;
                if (this.searchCriteria.reportDate != null
                    && typeof this.searchCriteria.reportDate === "object") {
                    const utcDate = new Date(this.searchCriteria.reportDate.getTime()
                        - (this.searchCriteria.reportDate.getTimezoneOffset() * 60000));

                    this.searchCriteria.reportDate = new Date(utcDate.toISOString().split("T")[0]);
                }

                getUserReports({body: this.searchCriteria})
                    .then((response) => {
                        this.myReports = response.data;
                        this.myReports.forEach((report) => {
                            report.outageStart = new Date(report.outageStart)
                                .toLocaleString("pl-PL", TimeUtils.options);
                            report.outageEnd = new Date(report.outageEnd)
                                .toLocaleString("pl-PL", TimeUtils.options);
                        });
                        this.loading = false;
                    });

                SearchCriteria.updateUrl(this);
            },

            computedAddress(address) {
                return address.streetType + " " + address.streetName + " " + address.houseNumber + ", " + address.city;
            },
        },
    };
</script>

<style lang="less">
@import "@/assets/theme/variable.less";
.public-user-reports-wrapper {
    padding-top: 4.5rem;
    .public-user-reports-bg {
        .public-user-reports-header-container {
            width: 100%;
            height: 300px;
            display: flex;
            &__text {
                background-color: @tint-bordeaux-00;
                width: 48%;
                padding: 50px 30px 50px 140px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 1250px) {
                    padding: 50px 30px 50px 80px;
                }
                @media screen and (max-width: 700px) {
                    width: 100%;
                    padding: 50px;
                }
                h1 {
                    color: white;
                    font-size: 80px;
                    font-weight: 900;
                    text-align: right;
                    @media screen and (max-width: 1550px) {
                        font-size: 70px;
                    }
                    @media screen and (max-width: 1398px) {
                        font-size: 60px;
                    }
                    @media screen and (max-width: 1125px) {
                        font-size: 50px;
                    }
                    @media screen and (max-width: 700px) {
                        font-size: 65px;
                    }
                    @media screen and (max-width: 575px) {
                        font-size: 50px;
                    }
                }
            }
            &__img {
                width: 52%;
                background-image: url("../../assets/myReportsBG.jpg");
                background-size: cover;
                background-position: 0 -120px;
                @media screen and (max-width: 1560px) {
                    background-position: center;
                }
                background-repeat: no-repeat;
                @media screen and (max-width: 700px) {
                    display: none;
                }
            }
        }
    }

    .public-user-reports-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        .public-user-reports-table-search-criteria {
            padding: 2rem 0;
            display: flex;
            gap: 2rem;
            flex-wrap: wrap;
            justify-content: center;
            width: 60%;
            @media screen and (max-width: 620px) {
                width: 80%;
            }
            @media screen and (max-width: 420px) {
                width: 90%;
            }
        }
        .reports-table {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            @media screen and (max-width: 620px) {
                width: 80%;
            }
            @media screen and (max-width: 420px) {
                width: 90%;
            }
        }
    }
}
</style>
