<template>
    <div class="outage-in-area-dialog">
        <PrimeDialog class="eon-dialog" :id="name" v-model:visible="display" :draggable="false"
                     :modal="true" :style="'width: ' + dialogWidth + 'px;'" :closable="false">
            <div class="outage-in-area-dialog-container">
                <div class="outage-in-area-dialog-text">
                    {{ computedText }}
                </div>
                <div class="outage-in-area-dialog-buttons">
                    <EonOutlinedButton class="turquoise-button" label="Nie" @click="reject" />
                    <EonButton class="turquoise-button" label="Tak" @click="confirm" />
                </div>
            </div>
        </PrimeDialog>
    </div>
</template>
<script>
    import PrimeDialog from "primevue/dialog";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";

    export default {
        name: "EonDialog",
        props: {
            name: {
                type: String,
            },
            icon: {
                type: String,
            },
            label: {
                type: String,
            },
            dialogWidth: {
                type: String,
            },
            buttonWidthExtension: {
                type: String,
                default: "0",
            },
            isPlanned: {
                type: Boolean,
                default: false,
            },
            showDialog: {
                type: Boolean,
                default: false,
            },
            outageType: {
                type: String,
                default: "",
            },
            outageStart: {
                type: String,
                default: "",
            },
            outageEnd: {
                type: String,
                default: "",
            },
        },
        emits: ["sendReport", "closeReport", "hideDialog"],
        components: {PrimeDialog, EonButton, EonOutlinedButton},
        data() {
            return {
                value: null,
                display: false,
            };
        },
        methods: {
            confirm() {
                this.$emit("sendReport");
                this.$emit("hideDialog");
            },
            reject() {
                this.$emit("closeReport");
                this.$emit("hideDialog");
            },
        },
        computed: {
            computedText() {
                // eslint-disable-next-line
                return this.isPlanned ? `We wskazanej przez Ciebie okolicy prowadzimy prace techniczne wymagające wyłączenia zasilania. ${this.outageStart !== "" ? `Szacowany czas wyłączenia ${this.outageStart} ${this.outageEnd}.` : ""} Czy na pewno chcesz dodać nowe zgłoszenie?` : "Odnotowaliśmy już zgłoszenia awarii dotyczące wskazanej przez Ciebie okolicy. Czy chcesz dodać nowe zgłoszenie?";
            },
        },
        watch: {
            showDialog(val) {
                this.display = val;
            },
        },

    };
</script>

<style lang="less">
    .outage-in-area-dialog {
        &-container {
        }
        &-text {
            text-align: center;
            margin-bottom: 50px;
        }
        &-buttons{
            display: flex;
            justify-content: center;
            gap: 50px;
        }
    }
</style>
