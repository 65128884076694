<template>
    <Toast style="width: auto"/>
    <div class="public-user-settings-wrapper">
        <div class="public-user-settings-bg">
            <div class="public-user-settings-header-container">
                <div class="public-user-settings-header-container__text">
                    <h1>Ustawienia konta</h1>
                </div>
                <div class="public-user-settings-header-container__img"></div>
            </div>
        </div>
        <div class="public-user-settings-form">
            <div class="flex justify-content-center">
                <VeeForm class="public-user-settings-form__data" @submit="saveData">
                    <h4 class="public-user-settings-data-processing-header mt-5 mb-3">
                        Chcesz otrzymywać na bieżąco informacje o przerwach w dostawach prądu?
                        Wyraź nam poniższą zgodę:
                    </h4>
                    <EonCheckbox name="dataProcessingConsent" class="checkbox-data-processing-consent"
                                 v-model="userSettingsData.dataProcessingConsent"
                                 @click="updateDataProcessingConsent()"
                                 label="Wyrażam zgodę na rzecz Stoen Operator Sp. z o.o. z siedzibą w
                        Warszawie, ul. Piękna 46 (00-672), na przetwarzanie moich danych osobowych
                        obejmujących adres e-mail i/lub nr telefonu komórkowego (w zależności od
                        zaznaczonego kanału kontaktu), w celu informowania mnie bezpośrednio o terminach
                        i czasie planowanych przerw w dostarczaniu energii elektrycznej oraz o awariach
                        sieci dystrybucyjnej Stoen Operator. Zgoda może być odwołana w każdym momencie,
                        jej wyrażenie jest dobrowolne, a jej wycofanie nie będzie skutkować żadnymi
                        negatywnymi konsekwencjami. Zgoda jest ważna do momentu jej odwołania."/>
                    <div v-if="userSettingsData.dataProcessingConsent">
                        <h2 class="public-user-settings-form-header">Wprowadź swoje dane</h2>
                        <div class="public-user-settings-form-inputs flex justify-content-between gap-6">
                            <div class="flex flex-column gap-5">
                                <EonInputText label="Imię i nazwisko*" name="name" class="form-input-width"
                                              v-model="userSettingsData.fullName" />
                                <div class="notification-info font-bold">
                                    Wybierz kanał, jakim chcesz otrzymywać powiadomienia:
                                </div>
                                <EonCheckbox label="Wyrażam zgodę na wysyłanie powiadomień poprzez email"
                                             name="emailNotificationAgreement" class="checkbox-alignment flex"
                                             v-model="userSettingsData.emailNotificationAgreement" />
                                <EonCheckbox label="Wyrażam zgodę na wysyłanie powiadomień poprzez SMS"
                                             name="smsNotificationAgreement" class="checkbox-alignment flex"
                                             v-model="userSettingsData.smsNotificationAgreement" />
                                <div class="notification-info">
                                    <span>
                                        W każdej chwili możesz zrezygnować z usługi powiadomień klikając w link:
                                    </span>
                                    <EonHref class="href-button" color="#1EA2B1"
                                             @click.prevent="deactivateNotificationConsent"
                                             href="#" label="Zrezygnuj"/>
                                </div>
                            </div>
                            <div class="flex flex-column gap-4">
                                <EonInputText label="Email*" name="email" class="form-input-width"
                                              v-model="userSettingsData.email" />
                                <EonInputNumber label="Telefon do powiadomień" name="phone"
                                                class="form-input-width phone"
                                                v-model="userSettingsData.phone" />
                                <EonButton label="Zapisz zmiany" class="turquoise-button mt-5 align-self-end"
                                           type="submit" />
                            </div>
                        </div>
                    </div>
                </VeeForm>
            </div>
            <div v-if="userSettingsData.dataProcessingConsent" class="flex justify-content-center">
                <VeeForm class="public-user-settings-form__password" @submit="savePassword">
                    <h2 class="public-user-settings-form-header">Zmień hasło</h2>
                    <div class="public-user-settings-form-inputs flex justify-content-between column-gap-4 mb-5">
                        <div class="flex flex-column gap-4">
                            <EonPassword class="input-password form-input-width" name="currentPassword"
                                         label="Bieżące hasło"
                                         v-model="userSettingsPassword.currentPassword" />
                            <EonPassword class="new-password form-input-width" name="newPassword"
                                         label="Nowe hasło" v-model="userSettingsPassword.newPassword"
                                         rules="required|validPassword"/>
                        </div>
                        <EonButton label="Zmień hasło"
                                   class="change-button-class-one turquoise-button mt-5 align-self-end"
                                   type="submit" />
                    </div>
                </VeeForm>
            </div>
            <div class="flex justify-content-center">
                <div class="public-user-settings-form__data">
                    <h2 class="public-user-settings-form-header">Usuń konto</h2>
                    <EonButton label="Usuń konto"
                               class="change-button-class-one align-self-start"
                               @click="openDeleteUserDialog()"/>
                    <span class="policy">
                        Stoen Operator Sp. z oo. jest administratorem Twoich danych osobowych znajdujących
                        się w formularzu i są one przetwarzane m. in. w celu określonym w treści zgody.
                        Informacje o Twoich prawach, danych kontaktowych naszych i inspektora ochrony
                        danych, podstawie prawnej przetwarzania, odbiorcach Twoich danych, okresie
                        przechowywania oraz dobrowolności podania danych i konsekwencjach ich niepodania
                        znajdują się
                        <a href="/public/privacy-policy" class="privacy-policy-href">tutaj</a>.
                    </span>
                </div>
            </div>
            <PrimeDialog class="public-user-delete-dialog" v-model:visible="showDeleteUserDialog"
                         :draggable="false" :modal="true" :closeable="false">
                <slot>
                    <h2>Na pewno?</h2>
                    <Toolbar>
                        <template #start>
                            <EonButton label="Tak" @click="deleteUser()"/>
                        </template>
                        <template #end>
                            <EonButton label="Anuluj" type="button" @click="closeDeleteUserDialog()"/>
                        </template>
                    </Toolbar>
                </slot>
            </PrimeDialog>
        </div>
    </div>
</template>

<script>
    import {Form as VeeForm} from "vee-validate";
    import EonButton from "@/components/form/EonButton";
    import EonInputText from "@/components/form/EonInputText";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import EonPassword from "@/components/form/EonPassword";
    import Toast from "primevue/toast";
    import {
        updatePublicUser,
        getPublicUser,
        changePasswordPublicUser,
        removeNotificationConsent,
        anonymizeMe,
        updateDataProcessingConsent,
    } from "@/swagger/vue-api-client";
    import EonInputNumber from "@/components/form/EonInputNumber";
    import {StatusCodes} from "@/utils/StatusCodes";
    import EonHref from "@/components/form/EonHref";
    import PrimeDialog from "primevue/dialog";
    import Toolbar from "primevue/toolbar";

    export default {
        name: "PublicUserSettingsView",

        components: {
            PrimeDialog,
            Toolbar,
            EonHref,
            EonInputNumber,
            VeeForm,
            EonButton,
            EonInputText,
            EonCheckbox,
            EonPassword,
            Toast,
        },

        data() {
            return {
                userSettingsData: {
                    dataProcessingConsent: false,
                    fullName: "",
                    email: "",
                    phone: null,
                    emailNotificationAgreement: false,
                    smsNotificationAgreement: false,
                },
                userSettingsPassword: {
                    newPassword: "",
                    currentPassword: "",
                },
                showDeleteUserDialog: false,
            };
        },

        beforeMount() {
            this.getData();
        },

        methods: {
            openDeleteUserDialog() {
                this.showDeleteUserDialog = true;
            },
            closeDeleteUserDialog() {
                this.showDeleteUserDialog = false;
            },
            deleteUser() {
                anonymizeMe().then(() => {
                    this.$emit("logout", "Konto zostało usunięte.");
                });
            },
            deactivateNotificationConsent() {
                if (this.userSettingsData.dataProcessingConsent) {
                    removeNotificationConsent().then(() => {
                        this.userSettingsData.smsNotificationAgreement = false;
                        this.userSettingsData.emailNotificationAgreement = false;
                        this.successToast("Powiadomienia są wyłączone");
                    });
                }
            },
            getClearUserSettingsPassword() {
                return {
                    newPassword: "",
                    currentPassword: "",
                };
            },
            successToast(message) {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    severity: "success",
                    summary: message,
                    life: 3000,
                    closable: true,
                });
            },
            errorToast(message) {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    severity: "warn",
                    summary: message,
                    life: 5000,
                    closable: true,
                    group: "login",
                });
            },
            getData() {
                getPublicUser().then((response) => {
                    this.userSettingsData = response.data;
                });
            },
            saveData() {
                updatePublicUser({body: this.userSettingsData}).then((response) => {
                    if (response.data) {
                        this.$emit("logout", "Dane zaktualizowano. Aby zalogować się potwierdź swój adres email.");
                    } else {
                        this.successToast("Dane zaktualizowano");
                    }
                }).catch((error) => {
                    switch (error.response.status) {
                        case StatusCodes.CONFLICT:
                            this.errorToast("Podany e-mail jest używany");
                            break;
                        default:
                    }
                });
            },
            savePassword() {
                changePasswordPublicUser({body: this.userSettingsPassword}).then(() => {
                    this.$emit("logout", "Hasło zaktualizowano. Zaloguj się za pomocą nowego hasła.");
                }).catch(() => {
                    this.errorToast("Niepoprawne bieżące hasło");
                    this.userSettingsPassword = this.getClearUserSettingsPassword();
                });
            },
            updateDataProcessingConsent() {
                updateDataProcessingConsent().then(() => {
                    this.successToast("Zgoda zaktualizowana");
                });
            },
        },
    };
</script>

<style lang="less" scoped>
@import "@/assets/theme/variable.less";
    .public-user-settings-wrapper {
        padding-top: 4.5rem;
        .public-user-settings-bg {
            .public-user-settings-header-container {
                width: 100%;
                height: 300px;
                display: flex;
                &__text {
                    background-color: @tint-bordeaux-00;
                    width: 48%;
                    padding: 50px 30px 50px 140px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 1250px) {
                        padding: 50px 30px 50px 80px;
                    }
                    @media screen and (max-width: 700px) {
                        width: 100%;
                        padding: 50px;
                    }
                    h1 {
                        color: white;
                        font-size: 80px;
                        font-weight: 900;
                        text-align: right;
                        @media screen and (max-width: 1550px) {
                            font-size: 70px;
                        }
                        @media screen and (max-width: 1398px) {
                            font-size: 60px;
                        }
                        @media screen and (max-width: 1125px) {
                            font-size: 50px;
                        }
                        @media screen and (max-width: 700px) {
                            font-size: 65px;
                        }
                        @media screen and (max-width: 575px) {
                            font-size: 50px;
                        }
                    }
                }
                &__img {
                    width: 52%;
                    background-image: url("../../assets/userSettingsBG.jpg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    @media screen and (max-width: 700px) {
                        display: none;
                    }
                }
            }
        }

        .public-user-settings-form {
            margin-bottom: 50px;
            .public-user-settings-form-header {
                font-size: 42px;
                font-weight: 700;
                color: @secondary-color;
                text-align: left;
                margin-top: 40px;
                margin-bottom: 20px;
            }
            .checkbox-data-processing-consent {
                text-align: left;
                font-size: 16px;
            }
            .notification-info {
                text-align: left;
                font-size: 16px;
                .href-button {
                    display: inline-flex;
                    vertical-align: text-bottom;
                    padding: 0 5px;
                    cursor: pointer;
                }
            }
            .policy {
                text-align: left;
                margin-top: 20px;
                font-size: 16px;
                .privacy-policy-href {
                    font-weight: bold;
                    display: inline-flex;
                    vertical-align: text-bottom;
                }
            }
            .public-user-settings-form__data, .public-user-settings-form__password {
                .public-user-settings-data-processing-header {
                    font-size: 16px;
                    font-weight: bold;
                    text-align: left;
                }
                width: 50%;
                max-width: 1000px;
                display: flex;
                flex-direction: column;
                .change-button-class-two {
                    display: none;
                }
                @media screen and (max-width: 1480px) {
                    width: 60%;
                }
                @media screen and (max-width: 1230px) {
                    width: 70%;
                }
                @media screen and (max-width: 1060px) {
                    width: 80%;
                }
                @media screen and (max-width: 940px) {
                    .public-user-settings-form-inputs {
                        flex-direction: column;
                        align-items: start !important;
                        .change-button-class-two {
                            display: block;
                        }
                        .change-button-class-one {
                            display: none;
                        }
                    }
                }
            }
            .form-input-width {
                width: 280px;
            }
            .form-input-width.phone {
                margin-top: 110px;
                position: relative;
                @media screen and (max-width: 940px) {
                    margin-top: 0;
                }
            }
        }
    }
</style>
<style lang="less">
@import "@/assets/theme/variable.less";
    @media screen and (max-width: 940px) {
        .public-user-settings-form {
            .eon-checkbox-field .eon-checkbox-off {
                display: flex;
                align-items: center;
                label {
                    text-align: left;
                }
            }
        }
    }
    @media screen and (min-width: 940px) {
        .public-user-settings-form {
            .checkbox-alignment {
                label {
                    display: inline-block;
                    width: 378px;
                }
            }
            &__data, &__password {
                .p-inputtext {
                    padding: 0.5rem;
                }
            }
        }
    }

    .public-user-settings-form {
        .checkbox-data-processing-consent {
            .eon-checkbox-off, .eon-checkbox-on {
                display: flex;
                align-items: center;
                label {
                    display: block;
                }
            }
        }
    }

    .public-user-delete-dialog {
        h2 {
            text-align: center;
        }
    }
    .public-user-delete-dialog.p-dialog-mask.p-component-overlay {
        background-color: transparent;
        backdrop-filter: none;
    }
    .public-user-delete-dialog.p-dialog {
        border-radius: 4px;
        box-shadow: 0 4px 4px 0  rgba(0, 0, 0, 0.25);
        border-bottom: 2px solid @color-turquoise;
        .p-dialog-header {
            display: none;
        }
        .p-dialog-content {
            background: @color-white;
            color: @color-black;
            padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        }
        .p-dialog-footer {
            border-top: 0 none;
            background: @color-white;
            color: @color-black;
            padding: 1rem 1.5rem;
            text-align: right;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            button {
                margin: 0 0.5rem 0 0;
                width: auto;
            }
        }
    }
</style>
