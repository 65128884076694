<template>
    <Toast />
    <!-- eslint-disable max-len -->
    <div class="address-wrapper">
        <div class="addressBG">
            <div class="address-header-container">
                <div class="address-header-container__text">
                    <h1>Moje adresy</h1>
                </div>
                <div class="address-header-container__img"></div>
            </div>
        </div>
        <div class="address-content">
            <h3 class="address-content-header">Wskaż interesujące Cię adresy</h3>
            <div class="address-content-body">
                <p>
                    <ul>Powiadomimy Cię o awariach i planowanych przerwach w dostawie prądu.</ul>
                    <ul>Wybrana przez Ciebie forma powiadomień to:
                        <span style="font-weight: bold">
                            {{notificationType}}
                        </span>.
                        <EonHref class="href-button" color="red" @click.prevent="pushToSettingsView"
                                 href="/user/settings" label="Przejdź do ustawień"/>
                    </ul>
                </p>
            </div>
            <div class="address-list">
                <div v-for="(address, index) in addresses" :key="index" class="address-item">
                    <div :class="{ 'address-card': address.confirmed, 'address-card-inactive': !address.confirmed }">
                        <div class="address-card-header">
                            <EonCheckbox class="inverted" v-model="address.confirmed" @click="updateAddressStatus(address)"/>
                            <EonIconButton iconColor="white" :withSvg="false" icon="pi pi-trash"
                                           @click="deleteAddress(address)"/>
                        </div>
                        <div class="address-info">{{ address.details }}</div>
                        <div v-if="address.confirmed" class="observation-badge">Obserwowany</div>
                    </div>
                </div>
                <div v-if="editableCard" class="address-item">
                    <div class="address-card address-card-editable">
                        <PlacesAutocomplete class="address-autocomplete"
                                            :google-maps-api="googleMapsApi"
                                            placeholder="Adres"
                                            @updateCoords="updateCoords"
                                            @updateAddressDetails="updateAddressDetails"/>
                        <p class="geolocation-error" v-if="spottedAddressError">
                            <i class="pi pi-exclamation-triangle" />
                            {{ spottedAddressError }}
                        </p>
                        <EonButton :disabled="isDisabledForm" label="Zapisz" class="inverted turquoise"
                                   @click="openShowAddressConfirmationDialog"/>
                        <EonOutlinedButton label="Anuluj" class="inverted turquoise"
                                           color="turquoise" @click="closeAddressForm"/>
                    </div>
                    <PrimeDialog class="address-confirmation-dialog" v-model:visible="showAddressConfirmationDialog"
                                 :draggable="false" :modal="true" :closeable="false">
                        <h2>Czy jesteś odbiorcą energii elektrycznej pod wskazanym adresem?</h2>
                        <slot>
                            <EonCheckbox v-model="addressConfirmation" name="addressConfirmation"
                                         class="flex p-4"
                                         label="Oświadczam, że jestem odbiorcą energii elektrycznej
                                                pod wskazanym adresem"/>
                            <Toolbar class="justify-content-around">
                                <template #start>
                                    <EonOutlinedButton label="Anuluj" type="button"
                                                       @click="closeShowAddressConfirmationDialog()" />
                                </template>
                                <template #end>
                                    <EonButton label="Zapisz" @click="saveAddress()"
                                               :disabled="!addressConfirmation"/>
                                </template>
                            </Toolbar>
                        </slot>
                    </PrimeDialog>
                </div>
                <!--eslint-disable-next-line-->
                <div class="address-card-new" @click="openAddressForm()"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import EonHref from "@/components/form/EonHref";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import EonIconButton from "@/components/form/EonIconButton";
    import PlacesAutocomplete from "@/components/form/PlacesAutocomplete";
    import EonButton from "@/components/form/EonButton";
    import {
        getUserNotificationType,
        getAddresses,
        saveAddress,
        updateAddressStatus,
        deleteAddress,
        getAreaByCoordinates as getAreaByCoords,
    } from "@/swagger/vue-api-client";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import {Loader} from "@googlemaps/js-api-loader";
    import PrimeDialog from "primevue/dialog";
    import Toolbar from "primevue/toolbar";

    export default {

        data() {
            return {
                googleMapsApi: null,
                notificationType: null,
                invalidLocation: false,
                invalidAddress: false,
                editableCard: false,
                showAddressConfirmationDialog: false,
                addressConfirmation: false,
                editableAddress: {
                    confirmed: true,
                    streetName: null,
                    houseNumber: null,
                    city: null,
                    district: null,
                },
                addresses: [],
            };
        },

        async beforeMount() {
            const loader = new Loader({
                apiKey: "AIzaSyBzgXAazWmBUNOesptSkmRKhA-EVDLE8DY",
                version: "weekly",
                libraries: ["places"],
            });
            this.apiPromise = loader.load();
            this.googleMapsApi = (await this.apiPromise).maps;

            this.getNotificationType();
            this.getAddresses();
        },

        computed: {
            isDisabledForm() {
                return this.invalidLocation || this.invalidAddress;
            },
            spottedAddressError() {
                if (this.invalidAddress) {
                    return "Wskaż numer domu";
                }
                if (this.invalidLocation) {
                    return "Adres nieobsługiwany";
                }
                return null;
            },
        },

        methods: {
            openShowAddressConfirmationDialog() {
                this.addressConfirmation = false;
                this.showAddressConfirmationDialog = true;
            },
            closeShowAddressConfirmationDialog() {
                this.showAddressConfirmationDialog = false;
            },
            updateCoords(coords) {
                this.validCoords(coords);
            },
            async validCoords(coords) {
                // exceptions in Stoen area - included on rendered map,
                // impossible to validate them using getAreaByCoords
                if (this.isAddressInExceptions()) {
                    this.invalidLocation = true;
                    return;
                }
                try {
                    await getAreaByCoords({body: coords});
                    this.invalidLocation = false;
                } catch (e) {
                    this.invalidLocation = true;
                }
            },
            isAddressInExceptions() {
                const streetNumber = this.editableAddress.houseNumber.toLowerCase();
                const street = this.editableAddress.streetName.toLowerCase();
                if (street === undefined || streetNumber === undefined) {
                    return false;
                }

                if (street.includes("kasprzaka")) {
                    return ["29", "29a", "29b", "29c", "31", "31a", "31b"].includes(streetNumber);
                }
                if (street.includes("gizów")) {
                    return ["1", "3"].includes(streetNumber);
                }
                if (street.includes("prymasa tysiąclecia")) {
                    return ["83a"].includes(streetNumber);
                }
                if (street.includes("konstruktorska")) {
                    return ["7", "9", "9a"].includes(streetNumber);
                }
                if (street.includes("wolska")) {
                    return ["165"].includes(streetNumber);
                }
                if (street.includes("jana kazimierza")) {
                    return ["28", "30", "32", "32a", "32b"].includes(streetNumber);
                }
                return false;
            },
            pushToSettingsView() {
                this.$router.push({name: "publicUserSettings"});
            },
            successToast(message) {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    severity: "success",
                    summary: message,
                    life: 3000,
                    closable: true,
                });
            },
            getNotificationType() {
                getUserNotificationType().then((response) => {
                    this.notificationType = response.data;
                });
            },

            getAddresses() {
                getAddresses().then((response) => {
                    this.addresses = response.data;
                });
            },

            openAddressForm() {
                this.editableCard = true;
            },

            closeAddressForm() {
                this.editableCard = false;
                this.invalidAddress = false;
                this.editableAddress = this.clearEditableAddress();
            },

            clearEditableAddress() {
                return {
                    confirmed: true,
                    streetName: null,
                    houseNumber: null,
                    city: null,
                    district: null,
                };
            },

            saveAddress() {
                saveAddress({body: this.editableAddress}).then(() => {
                    this.successToast("Zapisano adres: " + this.editableAddress.streetName + " "
                        + this.editableAddress.houseNumber);
                    this.closeAddressForm();
                    this.getAddresses();
                });
            },

            deleteAddress(address) {
                deleteAddress({id: address.id}).then(() => {
                    this.successToast("Usunięto adres: " + address.details);
                    this.getAddresses();
                });
            },

            updateAddressStatus(address) {
                updateAddressStatus({id: address.id, enabled: address.confirmed}).then(() => {
                    if (address.confirmed) {
                        this.successToast("Adres został aktywowany");
                    } else {
                        this.successToast("Adres został dezaktywowany");
                    }
                });
            },

            updateAddressDetails(address) {
                const getAddressDetail = function (name) {
                    const fields = address.filter((obj) => obj.types.includes(name));
                    if (fields.length > 0) return fields[0].long_name;
                    return undefined;
                };
                const subpremises = getAddressDetail("subpremise");
                this.editableAddress.houseNumber = getAddressDetail("street_number")
                    + (subpremises ? "/" + subpremises : "");
                this.editableAddress.streetName = getAddressDetail("route");
                this.editableAddress.city = getAddressDetail("locality");
                this.editableAddress.district = getAddressDetail("sublocality");

                this.invalidAddress = this.editableAddress.houseNumber === "undefined";
            },
        },

        components: {
            PrimeDialog,
            Toolbar,
            EonOutlinedButton,
            EonButton,
            PlacesAutocomplete,
            EonIconButton,
            EonCheckbox,
            EonHref,
            Toast,
        },
    };
</script>

<style lang="less" scoped>
@import "@/assets/theme/variable.less";
.address-wrapper {
    padding-top: 4.5rem;

    .addressBG {
        margin-bottom: 50px;
        .address-header-container {
            width: 100%;
            height: 300px;
            display: flex;
            &__text {
                background-color: @tint-bordeaux-00;
                width: 48%;
                padding: 50px 60px;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 700px) {
                    width: 100%;
                }
                h1 {
                    color: white;
                    font-size: 75px;
                    font-weight: 900;
                    text-align: right;
                    @media screen and (max-width: 1130px) {
                        font-size: 80px;
                    }
                    @media screen and (max-width: 940px) {
                        font-size: 60px;
                    }
                    @media screen and (max-width: 700px) {
                        font-size: 80px;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 60px;
                    }
                }
            }
            &__img {
                width: 52%;
                background-image: url("../../assets/userAddressBG.jpg");
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (max-width: 700px) {
                    display: none;
                }
            }
        }
    }

    .address-content {
        padding: 0 180px;
        @media screen and (max-width: 1000px) {
            padding: 0 100px;
        }
        @media screen and (max-width: 700px) {
            padding: 0 50px;
        }
        @media screen and (max-width: 450px) {
            padding: 0 20px;
        }
        text-align: left;
        margin-bottom: 100px;
        display: block;
        &-header {
            color: @color-turquoise-dark;
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 35px;
        }
        &-body {
            .href-button {
                display: inline-flex;
                vertical-align: text-bottom;
                padding: 0 5px;
                cursor: pointer;
            }
        }
        &-body p {
            font-size: 1.3em;
            text-align: justify;
        }
        &-body ul {
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 0;
            padding: 5px 0;
        }
    }

    .address-list {
        display: flex;
        flex-wrap: wrap;
        margin: 40px 0;
        .address-item {
            margin-right: 60px;
            margin-bottom: 60px;
            .address-card {
                position: relative;
                padding: 20px 20px;
                background-color: @color-turquoise;
                border-radius: 6px;
                width: 250px;
                height: 240px;
                .observation-badge {
                    position: absolute;
                    bottom: 12%;
                    right: -5%;
                    background-color: @color-turquoise-dark;
                    color: white;
                    padding: 5px 10px;
                    border-radius: 4px;
                }
                &-header {
                    display: flex;
                    justify-content: space-between;
                }
                &-editable {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .geolocation-error {
                        text-align: center;
                        margin-bottom: 0;
                        margin-top: 4px;
                        color: @color-white;
                    }
                }
                &-new {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
                .address-info {
                    color: @color-white;
                    margin-top: 35px;
                }
            }
            .address-card-inactive {
                position: relative;
                padding: 20px 20px;
                background-color: @trans-turquoise-50;
                border-radius: 6px;
                width: 250px;
                height: 240px;
                &-header {
                    display: flex;
                    justify-content: space-between;
                }
                .address-info {
                    color: @color-white;
                    margin-top: 35px;
                }
            }
        }

        .address-card-new {
            position: relative;
            padding: 20px 20px;
            border: 2px dashed @color-grey-steps;
            border-radius: 6px;
            width: 250px;
            height: 240px;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .address-card-new::before, .address-card-new::after {
            content: "";
            position: absolute;
            background-color: @color-grey-steps;
            border-radius: 6px;
        }
        .address-card-new::before {
            width: 8px;
            height: 35%;
        }
        .address-card-new::after {
            height: 8px;
            width: 35%;
        }
    }
}

@media screen and (max-width: 825px) {
    .address-card-new {
        margin-right: 60px;
    }
}
</style>
<style lang="less">
@import "@/assets/theme/variable.less";
    .address-confirmation-dialog {
        h2 {
            text-align: center;
        }
    }
    .address-confirmation-dialog.p-dialog-mask.p-component-overlay {
        background-color: transparent;
        backdrop-filter: none;
    }
    .address-confirmation-dialog.p-dialog {
        border-radius: 4px;
        box-shadow: 0 4px 4px 0  rgba(0, 0, 0, 0.25);
        border-bottom: 2px solid @color-turquoise;
        .p-dialog-header {
            display: none;
        }
        .p-dialog-content {
            background: @color-white;
            color: @color-black;
            padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        }
        .p-dialog-footer {
            border-top: 0 none;
            background: @color-white;
            color: @color-black;
            padding: 1rem 1.5rem;
            text-align: right;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            button {
                margin: 0 0.5rem 0 0;
                width: auto;
            }
        }
    }
</style>
